import React from 'react'
import cs from 'classnames'

export const Callout: React.FC<{
  bgColor?: string
  textColor?: string
  title?: string
}> = ({
  title,
  bgColor = 'bg-blue-50',
  textColor = 'text-gray-500',
  children
}) => {
  return (
    <div
      className={cs(
        'mt-8 px-6 py-4 rounded-md text-sm font-base',
        textColor,
        bgColor
      )}
    >
      👋 <span className='text-base font-bold'>{title}</span>
      <br />
      <div className='pl-4 px-4 py-2'>{children}</div>
    </div>
  )
}
