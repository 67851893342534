import React, { useCallback, useRef, useState } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CreditCardIcon
} from '@heroicons/react/solid'
import { Header } from './Header'
import Image from 'next/image'
import { CardType, PaymentMethod, ReceiptDetails } from 'lib/types'
import {
  getTotalAccountSetupFeesRoundedUSD,
  getTotalOnrampFeesRoundedUSD
} from 'lib/fees'
import { acalaSubscanUrl, isDev } from 'lib/config'
import { Callout } from 'components/Callout'

export const Receipt: React.FC<{
  receiptDetails: ReceiptDetails
}> = ({ receiptDetails }) => {
  const [,] = useState('')
  const [showOnrampFeeBreakdown, setShowOnrampFeeBreakdown] = useState(false)
  const [showAccountFeeBreakdown, setShowAccountFeeBreakdown] = useState(false)
  const [feesCurrency, setFeesCurrency] = useState('USD')
  const [fiatCurrency, setFiatCurrency] = useState('USD')

  const totalAccountSetupFees = getTotalAccountSetupFeesRoundedUSD(
    receiptDetails.fees
  )
  const totalOnrampFees = getTotalOnrampFeesRoundedUSD(receiptDetails.fees)
  const transactionUrl = `${acalaSubscanUrl}/extrinsic/${receiptDetails.txnHash}`

  const getCardLogo = (cardType: CardType) => {
    if (cardType === CardType.VISA) {
      return <Image src='/visa_logo.svg' width={30} height={25} />
    } else if (cardType === CardType.MASTERCARD) {
      return <Image src='/mastercard_logo.svg' width={30} height={25} />
    } else {
      return (
        <CreditCardIcon className='h-8 w-8 align-middle' aria-hidden='true' />
      )
    }
  }

  const refAnimationInstance = useRef<confetti.CreateTypes | null>(null)

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance
  }, [])

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      (refAnimationInstance.current as any)({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      })
  }, [])

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    })

    makeShot(0.2, {
      spread: 60
    })

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    })
  }, [makeShot])

  React.useEffect(() => {
    fire()
  }, [])

  return (
    <div className='flex flex-col'>
      <div className='px-4 space-y-4'>
        <Header name='Receipt' step={null} total={null} />
        <div className='flex flex-col items-center'>
          <div className='flex flex-col items-center pt-2 pb-10'>
            <div className='!border-solid !border-1 !border-black'>
              <Image src='/supercharge_logo.svg' width={40} height={40} />
            </div>
            <div className='font-bold text-black pb-4'>Supercharge</div>

            {isDev && (
              <Callout title='No test tokens'>
                While Acala has paused on-chain activity, we have temporarily
                disabled funding accounts with test tokens.
              </Callout>
            )}

            <div className='text-sm text-md text-gray-500 pt-4'>
              You purchased
            </div>
            <div className='text-4xl font-bold text-indigo-500 pt-4 pb-10'>
              {receiptDetails.purchaseCryptoCurrencyAmount}{' '}
              {receiptDetails.purchaseCryptoCurrency.toUpperCase()}
            </div>
            <div className='text-sm text-md text-gray-500 pb-2'>
              Receiving wallet:
            </div>
            <div className='block px-3 py-3 font-mono w-full text-sm border-1 rounded-md bg-gray-100 cursor-pointer text-gray-400 text-center focus:outline-none'>
              {receiptDetails.walletAddress}
            </div>
            {receiptDetails.txnHash && receiptDetails.txnHash.length > 0 && (
              <div className='mt-1 text-xs text-gray-400 pb-2 hover:text-gray-800 hover:underline'>
                <a
                  href={transactionUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  View transaction
                </a>
              </div>
            )}
          </div>

          <div className='w-full flex-grow border-t border-gray-300'></div>

          <div className='w-full pt-8'>
            <div className='text-base font-bold text-black align-left pb-4'>
              Order Details
            </div>
            <div className='flex flex-row w-full justify-between py-2'>
              <div className='text-sm text-gray-400'>Order id</div>
              <div className='text-sm text-black align-right'>
                {receiptDetails.orderId}
              </div>
            </div>
            <div className='flex flex-row w-full justify-between py-2'>
              <div className='text-sm text-gray-400'>Order timestamp</div>
              <div className='text-sm text-black align-right'>
                {new Date(
                  Date.parse(receiptDetails.orderTimestamp)
                ).toUTCString()}
              </div>
            </div>
            <div className='flex flex-row w-full justify-between pt-2 pb-2'>
              <div className='text-sm text-gray-400'>Payment method</div>
              {receiptDetails.paymentMethod === PaymentMethod.Card &&
                receiptDetails.cardType && (
                  <div className='text-sm text-black align-right'>
                    <div className='flex flex-row'>
                      <div className=''>
                        {getCardLogo(receiptDetails.cardType)}
                      </div>
                      <div className='ml-2'>
                        **** {receiptDetails.lastFourDigits}
                      </div>
                    </div>
                  </div>
                )}

              {receiptDetails.paymentMethod === PaymentMethod.ACH && (
                <div className='text-sm text-black align-right pb-2'>
                  <div className='flex flex-row'>
                    <div className=''>ACH</div>
                    <div className='ml-2'>
                      **** {receiptDetails.lastFourDigits}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='flex flex-row w-full justify-between pt-0 pb-2'>
              <div className='text-sm text-gray-400'>Exchange rate</div>
              <div className='text-sm text-black align-right'>
                {receiptDetails.exchangeRate.toFixed(4)}
              </div>
            </div>

            <div className='flex flex-row w-full justify-between py-2'>
              <div className='flex flex-row justify-none'>
                <div className='text-sm text-gray-400'>On-ramp fees</div>
                <dt className='ml-1 pt-0.5 flex-shrink-0 text-gray-400 hover:text-gray-500'>
                  {!showOnrampFeeBreakdown && (
                    <ChevronDownIcon
                      className='h-4 w-4'
                      aria-hidden='true'
                      onClick={() =>
                        setShowOnrampFeeBreakdown(!showOnrampFeeBreakdown)
                      }
                    />
                  )}

                  {showOnrampFeeBreakdown && (
                    <ChevronUpIcon
                      className='h-4 w-4'
                      aria-hidden='true'
                      onClick={() =>
                        setShowOnrampFeeBreakdown(!showOnrampFeeBreakdown)
                      }
                    />
                  )}
                </dt>
              </div>
              <div className='text-sm text-black align-right'>
                {totalOnrampFees.toFixed(2)} {feesCurrency}
              </div>
            </div>
            {showOnrampFeeBreakdown && (
              <div className='space-y-4'>
                <div className='pl-4 pt-2 flex items-center justify-between text-xs'>
                  <dt className='text-gray-400'>
                    {PaymentMethod[receiptDetails.paymentMethod]} fees
                  </dt>
                  <dd className='font-medium text-gray-900'>
                    {receiptDetails.fees.onRamp.toFixed(2)} {feesCurrency}
                  </dd>
                </div>
                <div className='pl-4 pb-2 flex items-center justify-between text-xs'>
                  <dt className='text-gray-400'>Network fees </dt>
                  <dd className='font-medium text-gray-900'>
                    {receiptDetails.fees.network.toFixed(4)} {feesCurrency}
                  </dd>
                </div>
              </div>
            )}
            {totalAccountSetupFees > 0 && (
              <div className='flex flex-row w-full justify-between py-2'>
                <div className='flex flex-row justify-none'>
                  <div className='text-sm text-gray-400'>
                    Account setup fees (ONE-TIME ONLY)
                  </div>
                  <dt className='ml-1 pt-0.5 flex-shrink-0 text-gray-400 hover:text-gray-500'>
                    {!showAccountFeeBreakdown && (
                      <ChevronDownIcon
                        className='h-4 w-4'
                        aria-hidden='true'
                        onClick={() =>
                          setShowAccountFeeBreakdown(!showAccountFeeBreakdown)
                        }
                      />
                    )}

                    {showAccountFeeBreakdown && (
                      <ChevronUpIcon
                        className='h-4 w-4'
                        aria-hidden='true'
                        onClick={() =>
                          setShowAccountFeeBreakdown(!showAccountFeeBreakdown)
                        }
                      />
                    )}
                  </dt>
                </div>
                <div className='text-sm text-black align-right'>
                  {totalAccountSetupFees.toFixed(2)} {feesCurrency}
                </div>
              </div>
            )}
            {totalAccountSetupFees > 0 && showAccountFeeBreakdown && (
              <div className='space-y-2 pb-4'>
                {receiptDetails.fees.accountSetup.firstTimeVerification > 0 && (
                  <div className='pl-4 pt-2 flex items-center justify-between text-xs'>
                    <dt className='text-gray-400'>
                      KYC verification (Charged by 3rd Party) fees
                    </dt>
                    <dd className='font-medium text-gray-900'>
                      {receiptDetails.fees.accountSetup.firstTimeVerification.toFixed(
                        2
                      )}{' '}
                      {feesCurrency}
                    </dd>
                  </div>
                )}
                {receiptDetails.fees.accountSetup.firstTimeACH > 0 && (
                  <div className='pl-4 pt-2 flex items-center justify-between text-xs'>
                    <dt className='text-gray-400'>
                      ACH setup (Charged by 3rd Party){' '}
                    </dt>
                    <dd className='font-medium text-gray-900'>
                      {receiptDetails.fees.accountSetup.firstTimeACH.toFixed(2)}{' '}
                      {feesCurrency}
                    </dd>
                  </div>
                )}
                {receiptDetails.fees.accountSetup.firstTimeCard > 0 && (
                  <div className='pl-4 pt-2 flex items-center justify-between text-xs'>
                    <dt className='text-gray-400'>
                      Card setup (Charged by 3rd Party){' '}
                    </dt>
                    <dd className='font-medium text-gray-900'>
                      {receiptDetails.fees.accountSetup.firstTimeCard.toFixed(
                        2
                      )}{' '}
                      {feesCurrency}
                    </dd>
                  </div>
                )}
              </div>
            )}
            <div className='flex flex-row w-full justify-between py-2 font-bold text-base'>
              <div>Amount charged</div>
              <div className='text-black align-right'>
                {receiptDetails.fiatAmount.toFixed(2)} {fiatCurrency}
              </div>
            </div>
          </div>
        </div>

        <ReactCanvasConfetti
          refConfetti={getInstance}
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0
          }}
          className='pointer-events-none'
        />
      </div>
    </div>
  )
}
