import React, { useState } from 'react'
import { Header } from '../Header'

export const Agreement: React.FC<{
  prev: (e) => void
  submit: (e) => void
  content: any
}> = ({ prev, submit, content }) => {
  const [disabled, setDisabled] = useState(true)

  return (
    <div>
      <Header name='KYC - Terms & Conditions' step={3} total={6} prev={prev} />

      <div className='px-4 py-6 items-center overflow-hidden overflow-y-scroll h-96'>
        {content ? (
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        ) : (
          <div className='flex justify-center items-center space-x-2'>
            <div>
              <div className='spinner-border self-center align-middle content-center w-10 h-10 border-4 text-indigo-700 border-solid rounded-full animate-spin'></div>
            </div>
          </div>
        )}
      </div>

      {content ? (
        <div className='relative px-4 pt-8 flex items-start'>
          <div className='flex items-center h-5'>
            <input
              id='comments'
              aria-describedby='comments-description'
              name='comments'
              type='checkbox'
              onClick={() => setDisabled(!disabled)}
              className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
            />
          </div>
          <div className='ml-3 text-sm'>
            <label htmlFor='comments' className='font-medium text-gray-700'>
              I agree to Prime Trust's terms 👈
            </label>
          </div>
        </div>
      ) : (
        <div className='relative px-4 pt-8 flex items-start'></div>
      )}
      <div className='w-full px-3'>
        {disabled ? (
          <button className='mt-4 rounded-lg font-bold text-lg px-4 py-3 border-0 w-full cursor-pointer text-white bg-gray-500'>
            Next
          </button>
        ) : (
          <button
            type='submit'
            disabled={disabled}
            onClick={submit}
            className='mt-4 rounded-lg font-bold text-white text-lg px-4 py-3 border-0 w-full cursor-pointer bg-indigo-500'
          >
            Next
          </button>
        )}
      </div>
    </div>
  )
}
