import React, { useState } from 'react'
import { Head } from 'components/Head'
import { useLocalStorage } from 'react-use'
import { Paper } from 'components/Paper'
import { PanelContainer } from 'components/PanelContainer'
import { Purchase } from 'components/ramp/Purchase'
import { ConnectWallet } from 'components/ramp/ConnectWallet'
import { MagicLogin } from 'components/ramp/Login'
import { Magic, MagicUserMetadata } from 'magic-sdk'
import { ChooseExternalFundsSource } from 'components/ramp/ChooseExternalFundsSource'

import { KYC } from 'components/ramp/KYC'
import {
  CardType,
  EmptyProps,
  OrderEventFulfilledBuy,
  PaymentMethod,
  ReceiptDetails,
  TokenCurrency
} from 'lib/types'
import { minAmount } from 'lib/config'
import { Receipt } from 'components/ramp/Receipt'
import api from 'lib/api-client'
import { isAccountOpen } from 'lib/utils/primetrust'
import ExternalFundsSources from 'components/ramp/ExternalFundsSources'
import { Confirm } from 'components/ramp/Confirm'
import { Order } from 'components/ramp/Order'
import { useCustomer } from 'lib/hooks/useCustomer'
import { PatriotModal } from 'components/ramp/PatriotModal'
import { useUser } from 'lib/hooks/useUser'
import { getDefaultFeesUSD } from 'lib/fees'
import { Footer } from 'components/Footer'
import { Navbar } from 'components/Navbar'
import { useRouter } from 'next/router'

/**
 * Enumerates pages in the on-ramp flow.
 */
enum Screen {
  // Initial screen.
  Purchase,
  // Magic login screen.
  Login,
  // KYC screen.
  KYC,
  // Connect a destination (optional?)
  ConnectWallet,
  // Allows users to select a payment method.
  ChooseExternalFundsSource,
  // Initialize payment infrastructure
  ExternalFundsSources,
  // Confirm buy through email
  Confirm,
  // Order Status
  Order,
  // Receipt
  Receipt
}

function prevScreen(cur: Screen, isLoggedIn, isAccountOpen): Screen {
  switch (cur) {
    case Screen.Purchase:
      return Screen.Purchase
    case Screen.Login:
      return Screen.Purchase
    case Screen.KYC:
      return isLoggedIn ? Screen.Purchase : Screen.Login
    case Screen.ConnectWallet:
      return isAccountOpen ? Screen.Purchase : Screen.KYC
    case Screen.ChooseExternalFundsSource:
      return Screen.ConnectWallet
    case Screen.ExternalFundsSources:
      return Screen.ChooseExternalFundsSource
    case Screen.Confirm:
      return Screen.ChooseExternalFundsSource
  }
  return Screen.Purchase
}

function nextScreen(cur: Screen, isLoggedIn, isAccountOpen): Screen {
  switch (cur) {
    case Screen.Purchase:
      return isLoggedIn
        ? isAccountOpen
          ? Screen.ConnectWallet
          : Screen.KYC
        : Screen.Login
    case Screen.Login:
      return isAccountOpen ? Screen.ConnectWallet : Screen.KYC
    case Screen.KYC:
      return Screen.ConnectWallet
    case Screen.ConnectWallet:
      return Screen.ChooseExternalFundsSource
    case Screen.ChooseExternalFundsSource:
      return Screen.ExternalFundsSources
    case Screen.ExternalFundsSources:
      return Screen.Confirm
    case Screen.Confirm:
      return Screen.Order
    case Screen.Order:
      return Screen.Receipt
  }
  return Screen.Purchase
}

const Ramp: React.FC<EmptyProps> = () => {
  const router = useRouter()
  const user = useUser()
  const { data, isLoading } = useCustomer()

  const [showPatriotModal, setshowPatriotModal] = useState(false)
  const [screen, setScreen] = useState<Screen>(Screen.Purchase)
  const [email, setEmail] = useState('')
  const [fiatAmount, setFiatAmount] = useState(minAmount.toString())
  const [tokenCurrency, setTokenCurrency] = useState(TokenCurrency.aUSD)
  const [destinationAddress, setDestinationAddress] = useState('')
  const [paymentMethod, setPaymentsMethod] = useState<PaymentMethod>(
    PaymentMethod.None
  )
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isOpenedAccount, setIsOpenedAccount] = useState(false)
  const [, setLegalName] = useState('')
  const [, setAccountId] = useState('')
  const [orderId, setOrderId] = useState('')
  const [fundTransferMethodId, setFundTransferMethodId] = useState('')
  const [hasShownPatriotAct, setHasShownPatriotAct] = useLocalStorage<boolean>(
    'hasShownPatriotAct',
    false
  )
  const [receiptDetails, setReceiptDetails] = useState<ReceiptDetails>({
    fiatAmount: Number(fiatAmount),
    walletAddress: '',
    purchaseCryptoCurrency: '',
    purchaseCryptoCurrencyAmount: 0,
    orderId: '',
    orderTimestamp: '',
    exchangeRate: 1.0,
    fees: getDefaultFeesUSD(Number(fiatAmount), paymentMethod, tokenCurrency),
    paymentMethod: paymentMethod,
    lastFourDigits: '',
    cardType: CardType.UNKNOWN,
    bankAccountType: '',
    bankName: '',
    txnHash: undefined
  })

  const desktop = 'hidden sm:flex'
  const mobile = 'flex sm:hidden'

  const advanceScreen = () => {
    return setScreen(nextScreen(screen, isLoggedIn, isOpenedAccount))
  }

  const onPurchaseComplete = async (amount: number, token: TokenCurrency) => {
    setFiatAmount(amount.toFixed(2))
    setTokenCurrency(token)
    advanceScreen()
  }

  const onPaymentMethodChosen = async (method: PaymentMethod) => {
    setPaymentsMethod(method)
    advanceScreen()
  }

  const onWalletEntered = (walletAddress: string) => {
    setDestinationAddress(walletAddress)
    advanceScreen()
  }

  const onLoggedIn = async (email: string) => {
    setEmail(email)

    // We're required to show user the patriot act if:
    // user is kyc'ing for the first time
    // we have not yet shown the Patriot act
    //
    // Use localstorage to persist state across
    // reloads, multiple pages, tabs, etc.
    const showPatriotActOnNonCustomer = async () => {
      if (window) {
        const { customer } = await api.customer()
        if (!customer) {
          setHasShownPatriotAct(true)
          setshowPatriotModal(true)
        }
        advanceScreen()
      }
    }

    // note that localstorage could be undefined first time
    if (!hasShownPatriotAct) {
      showPatriotActOnNonCustomer()
    } else {
      advanceScreen()
    }
  }

  const onPaymentIntialized = (fundTransferMethodId: string) => {
    setFundTransferMethodId(fundTransferMethodId)
    advanceScreen()
  }

  const onOrderPlaced = (orderEvent: OrderEventFulfilledBuy) => {
    if (orderEvent.buyerOrderId) {
      setOrderId(orderEvent.buyerOrderId)
    } else {
      console.error('Payment made but order id could not be found')
      console.error(orderEvent)
    }

    advanceScreen()
  }

  const onIdentityComplete = () => {
    advanceScreen()
  }

  const onOrderComplete = async () => {
    const orderDetails = await api.getOrderDetails(orderId)
    const receiptDetails = {
      fiatAmount: Number(fiatAmount),
      walletAddress: orderDetails.data.receiverAddress,
      purchaseCryptoCurrency: orderDetails.data.purchaseCryptoCurrency,
      purchaseCryptoCurrencyAmount:
        orderDetails.data.purchaseCryptoCurrencyAmount,
      orderId: orderId,
      orderTimestamp: orderDetails.data.createdAt,
      exchangeRate: orderDetails.data.exchangeRate,
      fees: orderDetails.data.fees,
      paymentMethod: orderDetails.data.paymentMethod,
      lastFourDigits: orderDetails.data.paymentMethodDetails.lastFourDigits,
      cardType: orderDetails.data.paymentMethodDetails.creditCardType,
      bankAccountType: orderDetails.data.paymentMethodDetails.bankAccountType,
      bankName: orderDetails.data.paymentMethodDetails.bankName,
      txnHash: orderDetails.data.txnHash
    }
    setReceiptDetails(receiptDetails)
    advanceScreen()
  }

  const prev = () => {
    return setScreen(prevScreen(screen, isLoggedIn, isOpenedAccount))
  }

  const render = () => {
    switch (screen) {
      case Screen.Purchase:
        return (
          <Purchase
            submit={onPurchaseComplete}
            savedEmail={email}
            hasShownPatriotAct={hasShownPatriotAct}
          />
        )
      case Screen.Login:
        return <MagicLogin prev={prev} submit={onLoggedIn} />
      case Screen.KYC:
        return (
          <KYC
            savedEmail={email}
            prev={prev}
            setLegalName={setLegalName}
            submit={onIdentityComplete}
          />
        )
      case Screen.ConnectWallet:
        return (
          <ConnectWallet
            prev={prev}
            existingWalletAddress={destinationAddress}
            submit={onWalletEntered}
          />
        )
      case Screen.ChooseExternalFundsSource:
        return (
          <ChooseExternalFundsSource
            prev={prev}
            submit={onPaymentMethodChosen}
          />
        )
      case Screen.ExternalFundsSources:
        return (
          <ExternalFundsSources
            savedEmail={email}
            prev={prev}
            submit={onPaymentIntialized}
            amount={Number(fiatAmount)}
            paymentMethod={paymentMethod}
            setAccountId={setAccountId}
          />
        )
      case Screen.Confirm:
        return (
          <Confirm
            paymentMethod={paymentMethod}
            amount={Number(fiatAmount)}
            prev={prev}
            submit={onOrderPlaced}
            walletAddress={destinationAddress}
            tokenCurrency={tokenCurrency}
            existingFundsTransferMethodId={fundTransferMethodId}
          />
        )
      case Screen.Order:
        return <Order orderId={orderId} submit={onOrderComplete} />
      case Screen.Receipt:
        return <Receipt receiptDetails={receiptDetails} />
    }
  }

  // Sets the login status at the page level
  const magicLoggedIn = async () => {
    if (window) {
      try {
        const magicPublishableKey = process.env
          .NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY as string

        const magic = new Magic(magicPublishableKey)
        const _loggedIn = await magic.user.isLoggedIn()
        if (_loggedIn && user) {
          setIsLoggedIn(_loggedIn)
          const userMetadata: MagicUserMetadata = await magic.user.getMetadata()
          setEmail(userMetadata.email || '')
        }
      } catch (error) {
        console.error('An unexpected error happened occurred:', error)
      }
    }
  }

  // Sets the account opened flag at the page level
  const primetrustAccountOpened = async () => {
    if (window) {
      if (data && !isLoading) {
        const { customer } = data
        if (customer && customer.ptAccountId) {
          const kycData = await api.getKYCAccount(customer.ptAccountId)
          setIsOpenedAccount(isAccountOpen(kycData))
        }
      }
    }
  }

  React.useEffect(() => {
    magicLoggedIn()
  }, [user])

  React.useEffect(() => {
    primetrustAccountOpened()
  }, [data, isLoading])

  // if ?ref specified, use the referral code
  React.useEffect(() => {
    const getQuery = (key: string): string => {
      let value = router.query[key] as string

      // `useRouter` is a React hook; it catches up to current query on
      // `ReactDOM.hydrate`. This workaround is to manually parse the
      // router path and extract the specific query param
      // See https://github.com/vercel/next.js/discussions/11484 for more

      if (!value) {
        const match = router.asPath.match(new RegExp(`[&?]${key}=(.*)(&|$)`))
        if (match && match.length > 1) {
          value = match[1]
        }
      }

      return value || ''
    }

    const partnerWalletAddress = getQuery('walletAddress')
    if (partnerWalletAddress) {
      setDestinationAddress(partnerWalletAddress)
    }
  }, [])

  return (
    <>
      <Head />

      <Paper>
        <Navbar email={email} />

        {showPatriotModal && (
          <PatriotModal
            showPatriotModal={showPatriotModal}
            setShowPatriotModal={setshowPatriotModal}
          />
        )}

        <PanelContainer>
          <div className='my-auto'>{render()}</div>
        </PanelContainer>
      </Paper>

      <Footer />
    </>
  )
}

export default Ramp
