import React, { useState } from 'react'
import { formDataToObject, customerToSentryUser } from 'lib/utils'

import {
  socureSDKEndpoint,
  socureDeviceRiskEndpoint,
  socureSDKApiKey
} from 'lib/config'

import Script from 'next/script'
import { Header } from '../Header'
import api from 'lib/api-client'
import { EmptyProps } from 'lib/types'
import cs from 'classnames'
import * as Sentry from '@sentry/nextjs'
import { useCustomer } from 'lib/hooks/useCustomer'

export const Spinner: React.FC<EmptyProps> = () => (
  <div
    className={cs(
      'spinner-border self-center',
      'align-middle content-center',
      'w-16 h-16',
      'border-4 text-blue-400 border-solid',
      'rounded-full animate-spin'
    )}
  ></div>
)

export const Socure: React.FC<{
  prev: () => void
  submit: () => void
  isKYCError: boolean
  socureScriptLoaded: boolean
  setSocureScriptLoaded: React.Dispatch<React.SetStateAction<boolean>>
  deviceScriptLoaded: boolean
  setDeviceScriptLoaded: React.Dispatch<React.SetStateAction<boolean>>
  phoneNumber: string
}> = ({
  prev,
  submit,
  isKYCError,
  socureScriptLoaded,
  setSocureScriptLoaded,
  deviceScriptLoaded,
  setDeviceScriptLoaded,
  phoneNumber
}) => {
  const { data, isLoading } = useCustomer()
  const [loading, setLoading] = useState(true)

  const setSentryUserContext = React.useCallback(() => {
    if (data && !isLoading) {
      const { customer } = data
      if (customer) {
        Sentry.configureScope((scope) => {
          scope.setUser(customerToSentryUser(customer))
        })
      }
    }
  }, [data, isLoading])

  const uploadDeviceId = async (sessionId) => {
    const formData = new FormData()
    formData.append('deviceSessionId', sessionId)
    const body = formDataToObject(formData)

    try {
      const res = await api.uploadSocureUuid(body)
    } catch (e) {
      const error = e as { message: string }
      return false
    }
  }

  const uploadSocureDocumentUuid = async (documentUuid) => {
    const formData = new FormData()
    formData.append('documentUuid', documentUuid)

    const body = formDataToObject(formData)

    try {
      const res = await api.uploadSocureUuid(body)
      return submit()
    } catch (e) {
      const error = e as { message: string }
      return false
    }
  }

  const onSuccess = (res) => {
    uploadSocureDocumentUuid(res.documentUuid)
    submit()
  }
  const onError = (e) => {
    setSentryUserContext()
    Sentry.captureException(new Error(e))
  }

  React.useEffect(() => {
    //   const socureSDKApiKey = process.env.NEXT_PUBLIC_SOCURE_SDK_API_KEY as string
    async function bootstrap() {
      if (socureScriptLoaded && deviceScriptLoaded) {
        const _w = window as any
        const SocureScript = await _w.SocureInitializer
        SocureScript.init(socureSDKApiKey).then((lib) => {
          setLoading(false)
          lib.cleanup()
          lib
            .init(socureSDKApiKey, '#socure-widget', {
              onSuccess: onSuccess,
              onError: onError,
              qrCodeNeeded: false
            })
            .then(function () {
              lib.start(1, phoneNumber)
            })
        })
        const devicer = await _w.devicer

        devicer.run(
          {
            publicKey: socureSDKApiKey,
            userConsent: true,
            context: 'signup'
          },
          function (response) {
            uploadDeviceId(response.sessionId)
          }
        )
      }
    }
    bootstrap()
  }, [socureScriptLoaded, deviceScriptLoaded, isKYCError])

  const render = () => (
    <div>
      <Script
        type='text/javascript'
        src={socureSDKEndpoint}
        onLoad={() => {
          setSocureScriptLoaded(true)
        }}
      ></Script>
      <Script
        type='text/javascript'
        src={socureDeviceRiskEndpoint}
        onLoad={() => {
          setDeviceScriptLoaded(true)
        }}
      ></Script>
      <div className='px-4 space-y-8'>
        <Header name='Socure' step={3} total={6} prev={prev} />
        {loading && (
          <div className='flex flex-col py-8 justify-center items-center space-y-8'>
            <Spinner />
          </div>
        )}
        <div id='socure-widget'></div>
      </div>
    </div>
  )

  return <div className='my-auto'>{render()}</div>
}
