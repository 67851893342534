import React, { useEffect, useState } from 'react'
import { Header } from './Header'
import { useChannel } from 'lib/hooks/useChannel'
import { isDev, supportEmail } from 'lib/config'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { waitSleep } from 'lib/utils'

export const Order: React.FC<{
  orderId: string
  submit: (orderId: string) => void
}> = ({ orderId, submit }) => {
  const [orderSuccess, setOrderSuccess] = useState<boolean>(false)
  const [orderFailed, setOrderFailed] = useState<boolean>(false)
  const [orderProcessing, setOrderProcessing] = useState<boolean>(false)
  const [orderWaitingTxn, setOrderWaitingTxn] = useState<boolean>(false)
  const [placingOrder, setPlacingOrder] = useState<boolean>(true)

  const [,] = useChannel('orderStatusEvents', (msg) => {
    if (msg.name === 'txnReady') {
      const data = msg.data as { orderId: string }
      if (data.orderId === orderId) {
        setOrderProcessing(true)
      }
    } else if (msg.name === 'txnBroadcasted') {
      const data = msg.data as { orderId: string }
      if (data.orderId === orderId) {
        setOrderWaitingTxn(true)
      }
    } else if (msg.name === 'orderSucceeded') {
      const data = msg.data as { orderId: string }
      if (data.orderId === orderId) {
        setOrderSuccess(true)
      }
    } else if (msg.name === 'orderFailed') {
      const data = msg.data as { orderId: string }
      if (data.orderId === orderId) {
        setOrderFailed(true)
      }
    }
  })

  useEffect(() => {
    if (orderSuccess) {
      submit(orderId)
    }

    async function sandboxProgressOrder() {
      if (orderWaitingTxn && isDev) {
        setOrderSuccess(true)
        setOrderWaitingTxn(false)
        await waitSleep(1000)
      }

      if (orderProcessing && isDev) {
        setOrderWaitingTxn(true)
        setOrderProcessing(false)
        await waitSleep(1000)
      }

      if (placingOrder && isDev) {
        setOrderProcessing(true)
        setPlacingOrder(false)
        await waitSleep(1000)
      }
    }

    sandboxProgressOrder()
  }, [
    orderSuccess,
    orderFailed,
    orderProcessing,
    orderWaitingTxn,
    placingOrder
  ])

  const render = () => {
    if (orderFailed) {
      return (
        <>
          <div className='relative space-y-12 pt-12'>
            <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-50'>
              <ExclamationCircleIcon
                className='h-6 w-6 text-gray-600'
                aria-hidden='true'
              />
            </div>

            <h3 className='text-center text-lg leading-8 px-12 font-medium tracking-tight text-gray-700 sm:text-xl'>
              Your order did not go through. All payments will be refunded.
            </h3>

            <h4 className='text-center text-md text-gray-300 px-4 sm:text-md'>
              Please reach out to us at {supportEmail}
            </h4>
            {orderId && (
              <h4 className='text-center text-md text-gray-300 px-4 sm:text-md'>
                Order Id: {orderId}
              </h4>
            )}
          </div>
        </>
      )
    }

    if (orderWaitingTxn) {
      return (
        <>
          <div className='flex flex-col justify-center space-y-6 py-10 items-center'>
            <div className='spinner-border self-center align-middle content-center w-10 h-10 border-4 text-indigo-700 border-solid rounded-full animate-spin'></div>
            <div>
              <h2 className='text-gray-600 text-md mt-4'>
                Waiting for transaction to be committed to blockchain...
              </h2>
            </div>
          </div>
        </>
      )
    }

    // Default, order is being placed with buyer
    return (
      <>
        <div className='flex flex-col justify-center space-y-6 py-10 items-center'>
          <div className='spinner-border self-center align-middle content-center w-10 h-10 border-4 text-indigo-700 border-solid rounded-full animate-spin'></div>
          <div>
            <h2 className='text-gray-600 text-md mt-4'>
              Processing your order....
            </h2>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='flex flex-col'>
        <div className='px-4 space-y-4'>
          <Header name={'Order Status'} step={null} total={null} />

          {render()}
        </div>
      </div>
    </>
  )
}
